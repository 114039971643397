.addmanager {
    width: 50vw;
    max-width: 750px;
    margin-top: 20px;
}
.addmanager .users-list {
    max-height: 80vh;
    margin-top: 10px;
    overflow: auto;
}
.addmanager .users-list .user-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
}
.addmanager .users-list .user-row:nth-child(even) {background: rgba(214, 214, 214, 0.192);}
.addmanager .user-row .s1 {
    display: flex;
    align-items: center;
}
.addmanager .s1, .addmanager .s2 {
    flex: 1;
    margin-right: 10px;
}
.addmanager .user-row .abr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: rgb(245, 99, 99);
    border-radius: 50%;
    color: var(--white);
    font-weight: bold;
}
.addmanager .user-row h3 {
    font-weight: 500;
}
.addmanager .user-row button, .addmanager .man-buttons button {
    display: block;
    height: 30px;
    background-color: var(--red);
    border: none;
    border-radius: 5px;
    color: var(--white);
    font-weight: bold;
}
.addmanager .man-buttons {
    display: flex;
    justify-content: flex-end;
    margin: 10px auto;
}
.addmanager .btn-cancel {
    margin-right: 5px;
    background-color: var(--warning) !important;
}
.addmanager .roles {
    min-width: 300px;
    margin: 20px auto;
}
.addmanager .roles .radio {
    margin: 10px auto;
    font-size: 1.3em;
    font-weight: 500;
}
.addmanager button:disabled {
    background-color: var(--berge);
}