.recap_title{
    background-color: var(--pinke);
    text-align: center;
    padding: 7px 0;
}
.group_card{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5;
}
.resum .bilans{
    margin-left: 2%;
}
.statgraphi{
    width: 95%;
    margin: 0 auto;
    padding: 15px 25px 25px 0 ;
}