.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 10vh;
    padding: 0px 2%;

    /* border-bottom: 1px solid var(--yellow); */
}

.header .go-back {
    position: absolute;
    left: 20px;
    cursor: pointer;
}

.header .notifs {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all .5s ease-out;
}

.header .notifs:hover {
    cursor: pointer;
}

.header .notifs:active {
    background-color: rgba(19, 19, 19, 0.377);
}

.header .number {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 5px;
    width: 20px;
    height: 20px;
    padding: 1px;
    background-color: var(--warning);
    border-radius: 50%;
    font-size: .9em;
    font-weight: 600;
    color: var(--white);
}

.header .number.problem {
    background-color: var(--red);
}

.header .user {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: 15px;
}

.header .user img {
    display: block;
    width: 2vw;
    /* height: 3vw; */
    margin-right: 15px;
    border-radius: 50%;
    border: none;
}

.header .user img:hover {
    cursor: pointer;
}



.beta-div {
    position: absolute;
    bottom: 0;
    left: 40%;
    width: 150px;
    margin: 0px auto;
    transform: translateY(10px);
    text-align: center;
    background-color: #ffee01;
    border-radius: 8px;
    color: var(--white);
    font-weight: bold;
}