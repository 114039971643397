.dashboard {
    position: relative;
    display: flex;
    /* border: 1px solid red; */
}
.side-bar {
    position: fixed;
    width: 15vw;
    height: 100vh;
    /* background-color: rgba(255, 85, 85, 0.226); */
    
}
.main-content {
    flex: 1;
    background-color: var(--background);
    margin-left: 15vw;
}
.head {
    /* border-bottom: 1px solid rgb(219, 218, 218); */
}