.sell {}

.sell .tx-list{
    display: flex;
    flex-direction: column-reverse;
}
.sell .chart {
    margin: 2%;
    padding: 2%;
    background-color: var(--white);
    border-radius: 5px;
}