.transaction {
    /* border: 1px solid #000; */
}

.transactions {
    margin: 2%;
    display: flex;
    flex-direction: column-reverse;
}
.transaction .tx-list{
    display: flex;
    flex-direction: column-reverse;
}

.transaction .head {
    display: flex;
    align-items: center;
    padding: 2%;
}

.asses {
    margin: 2%;
    padding: 2%;
    background-color: var(--white);
    border-radius: 5px;
}
.asses h1 {
    margin-bottom: 50px;
    text-align: center;
}
.assests {
    /* margin: 20px 0px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
.assest .amount {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    margin: auto;
    /* background-color: ; */
    color: var(--white);
    border-radius: 50%;
    /* border: 1px solid #000; */
}
.assests h2 {
}
.assests h4 {
    font-weight: 400;
}
.assests p {
    width: 200px;
    margin-top: 10px;
    font-weight: 300
}
.asses .bar {
    width: 50%;
    height: 6px;
    margin: 30px auto;
    background-color: var(--red);
    border-radius: 8px;
}
.asses .bilan {
    display: flex;
    justify-content: center;
    font-size: 2em;
}
.asses .bilan div:nth-child(2) {
    margin-left: 20px;
    color: var(--green);
    font-weight: bold;
}
.asses .bilan div.red-marge {
    color: red;
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}