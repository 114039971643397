.statementCard{
    display: flex;
    width: 45%;
    justify-content: space-around;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 0;
    margin: 15px;
}
.statementcard_left{
    width: 40%;
    padding: 0 0 0 15px;
}
.statement_right{
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.text_win{
    padding: 1em;
}
.nomber_win{
    float: left;
    font-size: 27px;
    transform: translate(-4px,-5px);
}
.statementCard .MuiCircularProgress-root{
    width: 75px!important;
    height: 75px!important;
}


@media screen and (max-width: 768px) {
    .statementCard{
        width: 93%;
    }
}