:root {
  --no-value: none;
  --white: #fff;
  --red: #cc1616;
  --second-red: #c31432;
  --red-transparent: #cc16160a;
  --blue: #0a0eff4d;
  --green: #3bc28e;
  --night-blue: #0f394d;
  --soft-night-blue: #0f394d8c;
  --black: #000000;
  --background: #0000000a;
  --white-background: #f3f7fa;
  --berge: #9b9b9b34;
  --warning: #ff9800;
  --yellow: #ffee01;
  --warning: #ff9800;
  --pinke: #ffafc1;
}

html,
body,
header,
main,
footer,
ul, h1, h2, h3, h4, h5 {
  margin: 0px;
  padding: 0px;
}

html {
  scroll-behavior: smooth;
}

.App {
  /* width: 100vw; */
  height: 100vh;
  /* border: 1px solid #000; */
  color: var(--night-blue);
}

li {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  outline: 0;
}

.box-shadows {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.shadows {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mv {
  margin: 30px auto;
}

.tx-id {
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.tx-id:hover {
  color: var(--red);
  text-decoration: underline;
}

/* modal */
.react-responsive-modal-modal {
  border-radius: 5px;
}

/* travailler la scroll bar */

::-webkit-scrollbar {
  width: 2px;
  /* height: 5px; */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(150deg, #fff1e0 30%, #cc1616 70%);
  border-radius: 3px;
}

/*::-webkit-scrollbar-thumb:hover {
  background: #b3afb3;
}*/
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}