.buy {}

.buy .tx-list {
    max-height: 600px;
    /* padding-bottom: 1%; */
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}

.buy .chart {
    margin: 2%;
    padding: 2%;
    background-color: var(--white);
    border-radius: 5px;
}
.buy_right .bilans:first-child{ 
    padding-left: 20px;
}